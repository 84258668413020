import { Component, Provide, ProvideReactive, Vue, Watch } from 'vue-property-decorator';
import { State, Action } from 'vuex-class';

// Enums
import EstimateDocumentNumberEnum from '@/ship/Enums/EstimateDocumentNumberEnum';
import EstimateStatusEnum from '@/ship/Enums/EstimateStatusEnum';
import { EstimateDocumentTypeEnum, EstimateTypeProperty } from '@/ship/Enums/EstimateDocumentTypeEnum';
import { EstimateTableActionEnum, EstimateUserTypeEnum } from '@/ship/Enums/EstimateEnums';
import PermissionsEnum from '@/ship/Enums/PermissionsEnum';
import ModalEnum from '@/ship/Enums/ModalEnum';

// Utils
import ErrorService from '@/utils/ErrorService';
import { downloadFile } from '@/utils/FilesDownloading';
import { fromISODateToLocalDate, fromLocalDateToISODate } from '@/utils/Helper';
import ModalService from '@/ship/Services/ModalService';
import { hasPermission } from '@/utils/Permissions';
import { syncFilesForEstimate } from '@/utils/Api';

// API
import {
    createEstimate,
    deleteEstimate,
    updateEstimateDate,
    sendEstimateForReview,
    sendEstimateForCancellation,
    sendEstimateForRevision,
    sendEstimateForApproval,
    sendEstimateForSync,
    getAdditionalEstimate,
    getEstimate,
    getAct,
} from '@/utils/Api';

// Interfaces
import { EstimateType, IEstimateAct } from '@/ship/Models/IEstimate';
import { estimateStatuses } from '@/components/Estimates/EstimateStatuses';

// Models
import User from '@/ship/Models/User';
import IFile from '@/ship/Models/IFile';

enum EstimateActionEnum {
    review = 'review',
    revision = 'revision',
    cancellation = 'cancellation',
    approval = 'approval',
    sync = 'sync',
}

@Component({
    components: {},
})
export default class VEstimatesMixin extends Vue {
    @State user!: User;
    @State users!: User[];
    @Action GET_USERS!: () => Promise<User[]>;

    @Provide() estimateObjectStatus = this.getEstimateStatus;
    @Provide() estimateObjectUserType = this.getEstimateUserType;

    readonly EstimateStatusEnum = EstimateStatusEnum;
    readonly EstimateActionEnum = EstimateActionEnum;
    readonly EstimateDocumentNumberEnum = EstimateDocumentNumberEnum;

    readonly statuses = estimateStatuses;

    public search = '';

    showForm = false;
    loading = false;

    estimate: EstimateType | null = null;
    additionals: EstimateType[] = [];
    acts: EstimateType[] = [];

    activeEstimate: EstimateType | null = null;
    chatEstimate: EstimateType | null = null;

    unreadMessagesCount: number = 0;

    readonly isPattern = 1;

    parentId: number | null = null;
    parentType: EstimateDocumentNumberEnum = EstimateDocumentNumberEnum.estimate;
    parentEstimate: EstimateType | null = null;

    get filesCount() {
        if (this.estimate && this.estimate.files) return this.estimate.files.data.length;
    }

    get chatTitle() {
        if (this.chatEstimate) {
            const title = this.$t(`page.estimates.${this.getType(this.chatEstimate)}.title`);
            return `${this.$tc('remark', 1)} ${title} ${this.$t('from')} ${this.getDate(this.chatEstimate)}`;
        }
    }

    get actionOptions() {
        if (this.activeEstimateType) {
            const options = [
                {
                    label: this.$t(`page.estimates.${this.activeEstimateType}.open`),
                    value: EstimateTableActionEnum.Open,
                },
            ];

            const historyOption = {
                label: this.$t(`page.estimates.history`),
                value: EstimateTableActionEnum.ShowHistory,
            };
            const historyTypes = [EstimateTypeProperty.AdditionalSmeta, EstimateTypeProperty.Act].includes(
                this.activeEstimateType,
            );
            if (historyTypes) options.push(historyOption);

            const updateOption = {
                label: this.$t(`page.estimates.${this.activeEstimateType}.data`),
                value: EstimateTableActionEnum.ChangeData,
            };
            if (this.canUpdate) options.push(updateOption);

            return options;
        }
    }

    get activeEstimateType() {
        if (this.activeEstimate) {
            return this.getType(this.activeEstimate);
        }
    }

    get activeEstimateDate() {
        if (this.activeEstimate) {
            return this.getDate(this.activeEstimate);
        }
    }

    get isDeletable() {
        return this.estimate?.status === EstimateStatusEnum.draft;
    }

    get estimateId() {
        return +this.$route.params.id;
    }

    get estimateType() {
        const components = this.$route.name?.split('.');
        if (components && components.length) {
            return components[components.length - 1];
        }
    }

    get estimateName() {
        let title = this.$t(`page.estimates.${this.estimateType}.title`);
        title += this.estimateNumber ? ` ${this.estimateNumber}` : '';
        title += this.estimateDate ? ` от ${this.estimateDate}` : '';

        return title;
    }

    get estimateNumber() {
        return this.estimate?.number;
    }

    get estimateDate() {
        if (this.estimate) {
            return this.getDate(this.estimate);
        }
    }

    get parentEstimateName() {
        const parentType = EstimateDocumentNumberEnum[this.parentType];
        let title = this.$t(`page.estimates.${parentType}.title`);
        title += this.parentEstimateNumber ? ` ${this.parentEstimateNumber}` : '';
        title += this.parentEstimateDate ? ` от ${this.parentEstimateDate}` : '';

        return title;
    }

    get parentEstimateNumber() {
        return this.parentEstimate?.number;
    }

    get parentEstimateDate() {
        if (this.parentEstimate) {
            return this.getDate(this.parentEstimate);
        }
    }

    get canAddPositions() {
        let statusCondition = false;
        if (this.estimate?.status) {
            statusCondition = [EstimateStatusEnum.draft].includes(this.estimate?.status);
        }
        const permissionCondition = hasPermission(PermissionsEnum.EstimatesImport);
        return statusCondition && permissionCondition;
    }

    get isFilled() {
        return this.estimate?.localSmeta?.data.length;
    }

    get canUpdate() {
        if (this.activeEstimate) {
            const isAdmin = hasPermission(PermissionsEnum.EstimatesUpdate);
            const hasUpdatePermissions = hasPermission(PermissionsEnum.EstimatesUpdateDate);
            const isTypeOk = [EstimateDocumentTypeEnum.act, EstimateDocumentTypeEnum.additional].includes(
                this.activeEstimate.object,
            );

            const isStatusOk = this.activeEstimate.status
                ? EstimateStatusEnum.agreed !== this.activeEstimate.status
                : false;

            const canUpdate = hasUpdatePermissions && isTypeOk && isStatusOk;
            return isAdmin || canUpdate;
        }
        return false;
    }

    get hasCreatePermission() {
        return hasPermission(PermissionsEnum.EstimatesCreate);
    }

    get isContractor() {
        if (this.user?.organisationId) {
            const isContractor = this.estimate?.generalContractorsIds?.includes(this.user.organisationId);

            return hasPermission(PermissionsEnum.EstimatesContractor) && isContractor;
        }
        return false;
    }

    get isEstimator() {
        const isPermissionsOk = hasPermission(PermissionsEnum.EstimatesEstimator);
        const isOrgOk = this.estimate?.customerId === this.user?.organisationId;
        const isEstimator = isOrgOk && isPermissionsOk;
        return isEstimator;
    }

    get isSupervisor() {
        return hasPermission(PermissionsEnum.EstimatesSupervisor);
    }

    get isProjectManager() {
        return hasPermission(PermissionsEnum.EstimatesProjectManager);
    }

    get isEstimatorRole() {
        const isEstimatorRole = this.isSupervisor || this.isProjectManager || this.isEstimator;
        return isEstimatorRole;
    }

    get isAgreedBySupervisor() {
        return !!this.estimate?.agreedBySupervisorAt;
    }

    get isAgreedByProjectManager() {
        return !!this.estimate?.agreedByProjectManagerAt;
    }

    get canSendToCancellation() {
        const isOkPermissions = hasPermission(PermissionsEnum.EstimatesSendForCancellation);

        return this.isAvailableAction(EstimateActionEnum.cancellation, this.estimate) && isOkPermissions;
    }

    get canSendToRevision() {
        const isOkPermissions = hasPermission(PermissionsEnum.EstimatesSendForRevision);
        return this.isAvailableAction(EstimateActionEnum.revision, this.estimate) && isOkPermissions;
    }

    get canSendToApproval() {
        const isOkPermissions = hasPermission(PermissionsEnum.EstimatesSendForApproval);
        return this.isAvailableAction(EstimateActionEnum.approval, this.estimate) && isOkPermissions;
    }

    get canSendToReview() {
        return (
            this.isAvailableAction(EstimateActionEnum.review, this.estimate) &&
            hasPermission(PermissionsEnum.EstimatesSendForReview)
        );
    }

    get canSendActToCancellation() {
        const conditionSV1 = this.estimate?.status
            ? [EstimateStatusEnum.checking, EstimateStatusEnum.fixing].includes(this.estimate?.status) &&
              this.isSupervisor &&
              !this.isAgreedBySupervisor
            : false;

        const conditionPM1 = this.estimate?.status
            ? [EstimateStatusEnum.checking, EstimateStatusEnum.agreedBySupervisor, EstimateStatusEnum.fixing].includes(
                  this.estimate?.status,
              ) &&
              this.isProjectManager &&
              !this.isAgreedByProjectManager &&
              this.isAgreedBySupervisor
            : false;

        const conditionEst1 = this.estimate?.status
            ? [
                  EstimateStatusEnum.checking,
                  EstimateStatusEnum.agreedByProjectManager,
                  EstimateStatusEnum.agreed,
                  EstimateStatusEnum.fixing,
              ].includes(this.estimate?.status) &&
              this.isEstimator &&
              this.isAgreedBySupervisor &&
              this.isAgreedByProjectManager
            : false;

        const isOk = conditionSV1 || conditionPM1 || conditionEst1;

        return isOk;
    }

    get canSendActToRevision() {
        const conditionSV1 = this.estimate?.status
            ? [EstimateStatusEnum.checking, EstimateStatusEnum.canceled].includes(this.estimate?.status) &&
              this.isSupervisor &&
              !this.isAgreedBySupervisor
            : false;

        const conditionPM1 = this.estimate?.status
            ? [
                  EstimateStatusEnum.checking,
                  EstimateStatusEnum.agreedBySupervisor,
                  EstimateStatusEnum.canceled,
              ].includes(this.estimate?.status) &&
              this.isProjectManager &&
              !this.isAgreedByProjectManager &&
              this.isAgreedBySupervisor
            : false;

        const conditionEst1 = this.estimate?.status
            ? [
                  EstimateStatusEnum.checking,
                  EstimateStatusEnum.agreedByProjectManager,
                  EstimateStatusEnum.canceled,
                  EstimateStatusEnum.agreed,
              ].includes(this.estimate?.status) &&
              this.isEstimator &&
              this.isAgreedBySupervisor &&
              this.isAgreedByProjectManager
            : false;

        const isOk = conditionSV1 || conditionPM1 || conditionEst1;

        return isOk;
    }

    get canSendActToApproval() {
        const conditionSV1 = this.estimate?.status
            ? [EstimateStatusEnum.checking, EstimateStatusEnum.canceled, EstimateStatusEnum.fixing].includes(
                  this.estimate?.status,
              ) &&
              this.isSupervisor &&
              !this.isAgreedBySupervisor
            : false;

        const conditionPM1 = this.estimate?.status
            ? [
                  EstimateStatusEnum.checking,
                  EstimateStatusEnum.agreedBySupervisor,
                  EstimateStatusEnum.canceled,
                  EstimateStatusEnum.fixing,
              ].includes(this.estimate?.status) &&
              this.isProjectManager &&
              !this.isAgreedByProjectManager &&
              this.isAgreedBySupervisor
            : false;

        const conditionEst1 = this.estimate?.status
            ? [
                  EstimateStatusEnum.checking,
                  EstimateStatusEnum.agreedByProjectManager,
                  EstimateStatusEnum.canceled,
                  EstimateStatusEnum.fixing,
              ].includes(this.estimate?.status) &&
              this.isEstimator &&
              this.isAgreedBySupervisor &&
              this.isAgreedByProjectManager
            : false;

        const isOk = conditionSV1 || conditionPM1 || conditionEst1;

        return isOk;
    }

    get canChangeDate() {
        return hasPermission(PermissionsEnum.EstimatesUpdateDate);
    }

    get canSendForSync() {
        return (
            this.isAvailableAction(EstimateActionEnum.sync, this.estimate) &&
            hasPermission(PermissionsEnum.EstimatesSendForSync)
        );
    }

    getEstimateStatus() {
        return this.estimate?.status;
    }

    getEstimateUserType() {
        let userType: EstimateUserTypeEnum = EstimateUserTypeEnum.Undefined;

        if (this.isSupervisor) {
            userType = EstimateUserTypeEnum.Supervisor;
        } else if (this.isProjectManager) {
            userType = EstimateUserTypeEnum.ProjectManager;
        } else if (this.isContractor) {
            userType = EstimateUserTypeEnum.Contractor;
        } else if (this.isEstimator) {
            userType = EstimateUserTypeEnum.Estimator;
        } else {
            userType = EstimateUserTypeEnum.Undefined;
        }

        return userType;
    }

    async getParentEstimate() {
        if (this.parentId && this.parentType) {
            if (
                this.parentType === EstimateDocumentNumberEnum.estimate ||
                this.parentType === EstimateDocumentNumberEnum.contract
            ) {
                this.parentEstimate = await getEstimate(this.parentId);
            }

            if (this.parentType === EstimateDocumentNumberEnum.additional) {
                this.parentEstimate = await getAdditionalEstimate(this.parentId);
            }
        }
    }

    async filesUploadHandler(estimate: EstimateType, files: IFile[]) {
        const filesIds = files.map(({ id }) => id);
        if (filesIds.length) await syncFilesForEstimate(estimate.id, filesIds);
        estimate.files = { data: files };
    }

    getType(estimate: EstimateType) {
        return EstimateTypeProperty[estimate.object];
    }

    getDate(estimate: EstimateType) {
        return fromISODateToLocalDate(estimate.date);
    }

    toggleOpen(estimate: EstimateType) {
        this.$set(estimate, 'isOpen', !estimate.isOpen);
    }

    toggleShowForm() {
        this.showForm = !this.showForm;
    }

    openChat(estimate: EstimateType) {
        this.chatEstimate = estimate;
        this.unreadMessagesCount = 0;
        this.showForm = true;
    }

    closeChat() {
        this.chatEstimate = null;
        this.showForm = false;
    }

    updateEstimateChatId(chatId: number, estimate: EstimateType) {
        if (estimate === this.estimate) {
            this.estimate.chatId = chatId;
            return;
        }

        if (estimate.object === EstimateDocumentTypeEnum.additional) {
            // estimate is additional
            this.additionals = this.updateChatIdInArray(this.additionals, estimate.id, chatId);
        } else if (estimate.parentType === EstimateDocumentNumberEnum.additional) {
            // estimate is act
            this.updateChatIdIfActInAdditional(chatId, estimate);
        } else {
            this.acts = this.updateChatIdInArray(this.acts, estimate.id, chatId);
        }
    }

    updateChatIdInArray(array: EstimateType[], id: number, chatId: number) {
        return array.map((el) => (el.id === id ? { ...el, chatId } : el));
    }

    updateAdditional(additional: EstimateType, actId: number, chatId: number) {
        if (additional.object === EstimateDocumentTypeEnum.additional && additional.acts) {
            const acts = {
                data: this.updateChatIdInArray(additional.acts.data, actId, chatId) as IEstimateAct[],
            };

            const res = { ...additional, acts };
            return res;
        }

        return additional;
    }

    updateChatIdIfActInAdditional(chatId: number, estimate: EstimateType) {
        const additional = this.additionals.find((el) => {
            if (el.object === EstimateDocumentTypeEnum.additional && el.acts) {
                return el.acts.data.includes(estimate as IEstimateAct);
            }
            return false;
        });

        if (additional) {
            this.additionals = this.additionals.map((el) =>
                el.id === additional.id ? this.updateAdditional(el, estimate.id, chatId) : el,
            );
        }
    }

    getCustomerName(estimate: EstimateType) {
        return estimate.customer?.data.shortName;
    }

    getObjectName(estimate?: EstimateType) {
        return estimate?.project.data.project;
    }

    getContractorsNames(estimate?: EstimateType) {
        const contractors = estimate?.generalContractors?.data || [];
        const names = contractors.map((el) => el.shortName);

        return names.join(', ');
    }

    async downloadEstimate(estimate: EstimateType, isPattern: number) {
        const type = EstimateTypeProperty[estimate.object] as string;
        const title = this.$t(`page.estimates.${type}.title`) as string;
        const number = estimate.number ? estimate.number : '';
        const name = estimate.name;
        const id = isPattern && estimate.parentId ? estimate.parentId : estimate.id;
        const url = `/estimate/${id}/excel?isPattern=${isPattern}`;
        try {
            const fileData = {
                url,
                name: `${title} ${number} ${name}.xlsx`,
            };

            await downloadFile(fileData);
        } catch (error) {
            ErrorService.handleApiError(error);
        }
    }

    async downloadTemplateEstimate(estimate: EstimateType) {
        const type = EstimateTypeProperty[estimate.object] as string;
        const title = this.$t(`page.estimates.${type}.title`) as string;
        const number = estimate.number ? estimate.number : '';
        const name = estimate.name;
        try {
            const fileData = {
                url: `/estimate/${estimate.id}/excel`,
                name: `${title} ${number} ${name}.xlsx`,
            };

            await downloadFile(fileData);
        } catch (error) {
            ErrorService.handleApiError(error);
        }
    }

    async createEstimate(type: number, estimate: EstimateType) {
        const estimateType = EstimateDocumentNumberEnum[type];
        const name = estimate.name;

        const request = {
            type,
            estimateId: estimate.id,
            name,
        };
        try {
            const { id } = await createEstimate(request);
            this.routeTo(id, estimateType);
        } catch (error) {
            ErrorService.handleApiError(error);
        }
    }

    async removeEstimate(id: number, outerId: number) {
        try {
            const { parentId, parentType } = await deleteEstimate(id);
            const estimateType = EstimateDocumentNumberEnum[parentType];
            if (outerId && parentId !== outerId) {
                this.routeTo(parentId, estimateType);
            }
        } catch (error) {
            ErrorService.handleApiError(error);
        }
    }

    routeTo(id: number, estimateType: string) {
        const route = {
            name: `estimates.${estimateType}`,
            params: { id: id.toString() },
        };

        this.$router.push(route);
    }

    async loadEstimate() {
        try {
            this.loading = true;
            const include =
                'customer,general_contractors,unread_messages_count,files,contractors,estimators,contractors_ids,estimators_ids';
            if (this.estimateType === 'estimate' || this.estimateType === 'contract') {
                this.estimate = await getEstimate(this.estimateId, {
                    include,
                });
                this.acts = this.estimate?.acts?.data ?? [];
                this.additionals = this.estimate?.smetaAdditionals?.data ?? [];
            } else if (this.estimateType === 'additional') {
                this.estimate = await getAdditionalEstimate(this.estimateId, {
                    include,
                });
                this.acts = this.estimate?.acts?.data ?? [];
                this.unreadMessagesCount = this.estimate.unreadMessagesCount || 0;
            } else {
                this.estimate = await getAct(this.estimateId, {
                    include,
                });
                this.unreadMessagesCount = this.estimate.unreadMessagesCount || 0;
            }

            if (this.estimate.parentId && this.estimate.parentType) {
                this.parentId = this.estimate.parentId;
                this.parentType = this.estimate.parentType;
                await this.getParentEstimate();
            }
        } catch (error) {
            ErrorService.handleApiError(error);
        } finally {
            this.loading = false;
        }
    }

    send(action: EstimateActionEnum, estimate: EstimateType | null) {
        if (estimate) {
            const titleAction = this.$t(`page.estimates.action.${action}`);
            let title = this.$t(`page.estimates.${this.estimateType}.send`);
            title += this.estimateNumber ? ` ${this.estimateNumber}` : '';
            title += ` ${titleAction}?`;
            const type = this.getType(estimate);
            ModalService.confirm({
                title,
                isDangerType: false,
                labelPositive: this.$t('send'),
                onConfirm: async () => {
                    switch (action) {
                        case EstimateActionEnum.review:
                            await this.sendEstimateForReview(estimate.id, type);
                            break;
                        case EstimateActionEnum.revision:
                            await this.sendEstimateForRevision(estimate.id, type);
                            break;
                        case EstimateActionEnum.cancellation:
                            await this.sendEstimateForCancellation(estimate.id, type);
                            break;
                        case EstimateActionEnum.approval:
                            await this.sendEstimateForApproval(estimate.id, type);
                            break;
                        case EstimateActionEnum.sync:
                            await this.sendEstimateForSync(estimate.id, type);
                            break;
                    }
                    await this.loadEstimate();
                },
            });
        }
    }

    async sendEstimateForReview(id: number, type: string) {
        try {
            await sendEstimateForReview(id);

            const message = this.$t(`page.estimates.${type}.sendToReviewSuccess`);
            this.showSuccessModal(message as string);
        } catch (error) {
            ErrorService.handleApiError(error);
        }
    }

    async sendEstimateForCancellation(id: number, type: string) {
        try {
            await sendEstimateForCancellation(id, this.getEstimateUserType());

            const message = this.$t(`page.estimates.${type}.sendToCancellationSuccess`);
            this.showSuccessModal(message as string);
        } catch (error) {
            ErrorService.handleApiError(error);
        }
    }

    async sendEstimateForRevision(id: number, type: string) {
        try {
            await sendEstimateForRevision(id, this.getEstimateUserType());

            const message = this.$t(`page.estimates.${type}.sendToRevisionSuccess`);
            this.showSuccessModal(message as string);
        } catch (error) {
            ErrorService.handleApiError(error);
        }
    }

    async sendEstimateForApproval(id: number, type: string) {
        try {
            await sendEstimateForApproval(id, this.getEstimateUserType());

            const message = this.$t(`page.estimates.${type}.sendToApprovalSuccess`);
            this.showSuccessModal(message as string);
        } catch (error) {
            ErrorService.handleApiError(error);
        }
    }

    async sendEstimateForSync(id: number, type: string) {
        try {
            await sendEstimateForSync(id);

            const message = this.$t(`page.estimates.${type}.sendToSyncSuccess`);
            this.showSuccessModal(message as string);
        } catch (error) {
            ErrorService.handleApiError(error);
        }
    }

    showSuccessModal(title: string, label = this.$t('great')) {
        this.$modal.show(ModalEnum.Success, { title, label });
    }

    isAvailableAction(action: EstimateActionEnum, estimate: EstimateType | null) {
        if (estimate && estimate.status) {
            switch (action) {
                case EstimateActionEnum.cancellation:
                    return [
                        EstimateStatusEnum.fixing,
                        EstimateStatusEnum.checking,
                        EstimateStatusEnum.agreed,
                        EstimateStatusEnum.agreedBySupervisor,
                        EstimateStatusEnum.agreedByProjectManager,
                    ].includes(estimate.status);
                case EstimateActionEnum.revision:
                    return [
                        EstimateStatusEnum.canceled,
                        EstimateStatusEnum.checking,
                        EstimateStatusEnum.agreed,
                        EstimateStatusEnum.agreedBySupervisor,
                        EstimateStatusEnum.agreedByProjectManager,
                    ].includes(estimate.status);
                case EstimateActionEnum.approval:
                case EstimateActionEnum.sync:
                    return [
                        EstimateStatusEnum.canceled,
                        EstimateStatusEnum.fixing,
                        EstimateStatusEnum.checking,
                        EstimateStatusEnum.agreedBySupervisor,
                        EstimateStatusEnum.agreedByProjectManager,
                    ].includes(estimate.status);
                case EstimateActionEnum.review:
                    return [EstimateStatusEnum.draft, EstimateStatusEnum.fixing].includes(estimate.status);
            }
        }
    }

    async updateDate(newDate: string, estimate: EstimateType | null) {
        const date = fromLocalDateToISODate(newDate);
        const estimateDate = fromISODateToLocalDate(estimate?.date);

        if (newDate !== estimateDate) {
            try {
                if (estimate) {
                    await updateEstimateDate(estimate.id, { date });
                    await this.loadEstimate();
                }
            } catch (error) {
                ErrorService.handleApiError(error);
            }
        }
    }

    async loadUsers() {
        if (!this.users) {
            await this.GET_USERS();
        }
    }
}
